import { KamonaSpinner } from '@/ui/svgs/kamona-spinner';
import { MoniepointLogoLetter } from '@/ui/svgs/moniepoint-logo-letter';

import { cn } from '@/lib/utils';

interface MoniepointIconLoaderProps {
  classNames?: {
    root?: string;
    spinner?: string;
    logo?: string;
  };
}

export const MoniepointIconLoader = ({
  classNames,
  ...props
}: Readonly<MoniepointIconLoaderProps>) => {
  return (
    <div
      {...props}
      aria-hidden="true"
      className={cn('h-12 w-12 text-ui-border-default', classNames?.root)}
    >
      <KamonaSpinner className={cn('absolute inset-0', classNames?.spinner)} />
      <MoniepointLogoLetter
        className={cn(
          'absolute left-1/2 top-1/2 h-auto w-6 -translate-x-1/2 -translate-y-1/2',
          classNames?.logo,
        )}
      />
    </div>
  );
};
