import { Column, Header, TypographyProps } from '@kamona/components';

import { cn } from '@/lib/utils';

import { PageSubtitle } from './page-subtitle';

export type PageTitleProps = React.ComponentPropsWithoutRef<'div'> &
  TypographyProps & {
    text: React.ReactNode;
    subtitle?: React.ReactNode;
  };

export function PageTitle({
  text,
  subtitle,
  className,
  align = 'center',
  weight = 'bold',
}: PageTitleProps) {
  return (
    <Column className={cn('gap-y-units-unit24 text-center', className)}>
      <Header
        as="h1"
        aria-label="page-title"
        color="content-heading-default"
        {...{ align, weight }}
      >
        {text}
      </Header>
      {subtitle &&
        (typeof subtitle == 'string' ? (
          <PageSubtitle aria-label="page-subtitle" align={align}>
            {subtitle}
          </PageSubtitle>
        ) : (
          subtitle
        ))}
    </Column>
  );
}
