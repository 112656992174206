import { Accordion, AccordionItem } from '@kamona/components';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { Card } from '@/ui/components/card';
import { ChipSelect } from '@/ui/components/chip-select';
import { Column } from '@/ui/components/flex';

import { useSearchParams } from '@/shared/hooks';

import { useSuppportModal } from '../../modal';
import { useGetFaqs } from '../hooks/use-get-faqs';
import { FAQs, TabData } from '../types';

function Chips({
  tab,
  onTabChange,
  tabs,
}: Readonly<{
  tab: TabData;
  onTabChange: (tab: TabData) => void;
  tabs: TabData[];
}>) {
  return (
    <ChipSelect
      chipProps={{
        variant: 'faded',
        className: {
          root: 'px-units-unit20 min-h-units-unit40',
        },
      }}
      className="flex-nowrap overflow-x-auto no-scrollbar"
      getOptionLabel={(option) => option.label}
      isOptionSelected={(option) => option.value === tab.value}
      onValueChange={onTabChange}
      options={tabs}
      value={tab}
      selectedChipProps={{ variant: 'filled', appearance: 'primary' }}
    />
  );
}

function Accordions({ tab }: Readonly<{ tab: TabData }>) {
  return (
    <Card variant="faded" layout="vertical">
      <Accordion variant="default" allowMultipleExpansion>
        {tab.faqs.map((faq, index) => (
          <AccordionItem
            key={`${faq.question}-${index}`}
            title={faq.question}
            value={`accordion-${index}`}
            className={{ icon: 'size-5' }}
          >
            <div dangerouslySetInnerHTML={{ __html: faq.answerInHtml }} />
          </AccordionItem>
        ))}
      </Accordion>
    </Card>
  );
}

const formatFaqsDataIntoTabs = (data: FAQs): TabData[] => {
  // Map categories to TabData
  const finalData: TabData[] =
    data?.categories.map((category) => ({
      faqs: category.faqs,
      value: category.name.toLocaleLowerCase().replace(/[^a-z0-9]+/g, '-'),
      label: category.name,
    })) || [];

  // Create the "all" record that contains all FAQs
  const allFaqsRecord = {
    faqs: finalData.flatMap((tab) => tab.faqs), // Combine all FAQs
    value: 'all',
    label: 'All',
  };

  // Add the "all" record at the beginning of the finalData array
  finalData.unshift(allFaqsRecord);

  return finalData;
};

export function SupportFAQs() {
  const { isSupportModalOpen } = useSuppportModal();
  const router = useRouter();
  const pathname = usePathname();
  const { getParam } = useSearchParams();
  const { data, isLoading, isError } = useGetFaqs();
  const [tab, setTab] = useState<TabData>();

  const finalData = formatFaqsDataIntoTabs(data ?? { categories: [] });

  useEffect(() => {
    setTab(
      finalData.find((tab) => tab.value === getParam('tab')) || finalData[0],
    );
  }, [data]);

  if (isLoading) return <div>Loading FAQs...</div>;

  if (isError) return <div>Error loading FAQs</div>;

  function onTabChange(tab: (typeof finalData)[0]) {
    setTab(tab);
    if (!isSupportModalOpen) {
      router.push(`${pathname}?tab=${tab.value}`, { scroll: false });
    }
  }

  return (
    <Column gap="units-unit16">
      {tab && (
        <>
          <Chips {...{ tab, onTabChange }} tabs={finalData} />
          <Accordions key={JSON.stringify(tab)} {...{ tab }} />
        </>
      )}
    </Column>
  );
}
