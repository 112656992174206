'use client';

import { Avatar } from '@kamona/components';
import { ChevronDown } from 'lucide-react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';

import { useGetMyProfile } from '@/features/shared/profile/hooks/use-get-my-profile';
import { useSuppportModal } from '@/features/shared/support/modal';

import { useMediaQuery } from '@/shared/hooks';
import { ROUTES } from '@/shared/routes';
import {
  capitalizeString,
  getUserInitials,
  isRouteMatch,
  restrictedRoutes,
} from '@/shared/utils';

import { cn } from '@/lib/utils';

import { Button } from '../button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import { Row } from '../flex';
import { Navlink } from '../navlink';
import { ProfileOptionsDropdown } from '../profile-options-dropdown';
import { Text } from '../text';

export const ProfileBadge = () => {
  const pathname = usePathname();
  const shouldFetchProfile = !isRouteMatch(pathname, restrictedRoutes);
  const { toggleSupportModal, isSupportModalOpen } = useSuppportModal();
  const isDesktop = useMediaQuery('md');
  const { data: profile, error } = useGetMyProfile({
    retry: false,
    enabled: shouldFetchProfile,
  });

  if (error || !shouldFetchProfile) {
    return null;
  }

  const { firstName, lastName, profileImageUrl, email } = profile || {};
  const userInitials = getUserInitials({
    firstName: firstName ?? '',
    lastName: lastName ?? '',
  });

  const RootElement = isDesktop ? DropdownMenu : React.Fragment;
  const TriggerElement = isDesktop ? DropdownMenuTrigger : Row;

  const ProfileIcon = (
    <Avatar
      src={profileImageUrl}
      size="large"
      text={userInitials}
      appearance="ghost"
      shape="circle"
    />
  );

  return (
    <RootElement>
      {isDesktop ? (
        <>
          <TriggerElement aria-label="profile-badge">
            <Button
              as="div"
              variant="link"
              appearance="plain"
              endIcon={<ChevronDown size={'20px'} />}
              className={{
                root: cn(
                  'gap-x-spacing-spacing3 no-underline flex flex-row items-center justify-center',
                ),
              }}
            >
              {ProfileIcon}
              <Text
                as="span"
                variant="body-default"
                weight="semibold"
                color="content-subtext-default"
              >
                {`${capitalizeString(firstName ?? '')} ${capitalizeString(lastName ?? '')}`}
              </Text>
            </Button>
          </TriggerElement>
          <ProfileOptionsDropdown
            as={DropdownMenuContent}
            className={'absolute top-units-unit10'}
            name={name}
            email={email}
            align="start"
          />
        </>
      ) : (
        <Navlink
          onClick={() => isSupportModalOpen && toggleSupportModal()}
          as={Link}
          variant="link"
          href={ROUTES.SETTINGS.$INDEX(pathname)}
          appearance="plain"
          className={cn(
            'gap-x-0 md:gap-x-spacing-spacing3 flex flex-row items-center justify-center',
          )}
        >
          {ProfileIcon}
        </Navlink>
      )}
    </RootElement>
  );
};
