'use client';

import { AlertModalProps, notification } from '@kamona/components';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useGetMyProfile } from '@/features/shared/profile/hooks';

import { queueSupportCall } from '@/app/actions/queue-support-call';

import { AlertDialogThumbnail } from '@/ui/components/alert-dialog';

import { checkUserLoggedIn } from '@/shared/utils';

import { SupportCallFormValues } from '../types';

import { config } from '@/config';

function checkIfSupportIsAvailable() {
  const now = new Date();

  // Convert current time to GMT+1
  const nowInGMTPlus1 = new Date(
    now.toLocaleString('en-US', { timeZone: 'Africa/Lagos' }),
  );

  // Get day, hour, and minutes
  const day = nowInGMTPlus1.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
  const hour = nowInGMTPlus1.getHours();
  const minute = nowInGMTPlus1.getMinutes();

  // Check if current time is between Mon to Fri and between 8am to 5pm
  const isWeekday = day >= 1 && day <= 5; // Mon to Fri
  const isWithinHours =
    hour >= 8 && (hour < 17 || (hour === 17 && minute === 0)); // 8am to 5pm

  return isWeekday && isWithinHours;
}

interface UseSupportCallFormOptions {
  setAlertModalProps: (props: AlertModalProps) => void;
  onOpenChange?: (open: boolean) => void;
}

export function useSupportCallForm({
  setAlertModalProps,
  onOpenChange,
}: Readonly<UseSupportCallFormOptions>) {
  const isSupportAvailable = checkIfSupportIsAvailable();
  const isUserLoggedIn = checkUserLoggedIn();
  const { data: profile } = useGetMyProfile({
    retry: false,
    enabled: isUserLoggedIn,
  });
  const methods = useForm({
    defaultValues: {
      phoneNumber: profile?.phoneInfo.number ?? '',
      isSupportAvailable,
    },
  });
  const [loading, setLoading] = useState(false);

  async function onSubmit(data: SupportCallFormValues) {
    const { phoneNumber, isSupportAvailable } = data;

    setLoading(true);
    try {
      // Prepare payload
      const payload = {
        firstName:
          isUserLoggedIn && profile?.firstName
            ? profile?.firstName
            : phoneNumber,
        phone: phoneNumber,
        client: config.XCALLY_CLIENT_NAME,
      };

      // Call the Server Action
      const response = await queueSupportCall(payload);

      // Check for errors in the response
      if (response.error) {
        // Handle error
        notification.error({
          title: 'An error occurred',
          description: response.error,
          position: 'top-center',
          variant: 'filled',
        });
      } else {
        // Handle success
        onOpenChange?.(false);
        setAlertModalProps({
          centered: true,
          open: true,
          title: isSupportAvailable
            ? "You'll receive a call soon"
            : "We'll reach out soon",
          description: isSupportAvailable
            ? "Heads up! We'll be calling you in 15-30 minutes. Keep your phone with you and notifications on!"
            : `You'll receive a call from us once we are available. Available ${config.SUPPORT_AVAILABILITY}.`,
          icon: (
            <AlertDialogThumbnail
              alt="Hour glass image"
              width={128}
              src="/assets/images/hourglass.png"
            />
          ),
          acceptLabel: 'Continue',
          onAccept: () => {
            setAlertModalProps({});
          },
          cancelLabel: '',
        });
      }
    } catch (error) {
      // This is for unexpected errors
      notification.error({
        title: 'An unexpected error occurred',
        description: 'Please try again later.',
        position: 'top-center',
        variant: 'filled',
      });
    } finally {
      setLoading(false);
    }
  }
  return {
    isSupportAvailable,
    isSubmitting: loading,
    onSubmit: methods.handleSubmit(onSubmit),
    initialValues: { phoneNumber: profile?.phoneInfo.number ?? '' },
    methods,
  };
}
