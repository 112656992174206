import { Column, Flag, FlagCode, XXSmallBody } from '@kamona/components';
import { InformationCircle } from '@kamona/icons-v2';
import { CountryCode, E164Number } from 'libphonenumber-js/core';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input/input-mobile';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';

import { cn } from '@/lib/utils';

import { ERROR_MESSAGES } from './constants';
import { PhoneNumberInputProps } from './types';

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  name,
  defaultCountry,
  label,
}) => {
  const { setValue, getValues, setError, clearErrors, formState, control } =
    useFormContext();

  const error = formState.errors[name ?? '']?.message;

  const onChangeHandler = (value: E164Number) => {
    setValue(name ?? '', value);
    clearErrors(name);
    if (value && name && value.length > 3) {
      const isUKPhoneNum = value.startsWith('+447');
      const isInvalid = !isValidPhoneNumber(value);
      if ((!isUKPhoneNum && isInvalid) || (isUKPhoneNum && value.length > 13)) {
        setError(name, {
          type: 'validate',
          message: ERROR_MESSAGES.invalid,
        });
      } else {
        clearErrors(name);
      }
    }
  };

  const onBlurHandler = () => {
    if (!name) return;

    const value = getValues(name);

    if (!value) {
      setError(name, {
        type: 'validate',
        message: ERROR_MESSAGES.required,
      });
    } else if (!isValidPhoneNumber(value)) {
      setError(name, {
        type: 'validate',
        message: ERROR_MESSAGES.invalid,
      });
    } else {
      clearErrors(name);
    }
  };

  /**
   * NOTE: we need this validateHandler
   * As this require to handle the validation
   * which is needed for the formState
   */
  const validateHandler = (value: E164Number) => {
    if (value && name && value.length > 3) {
      const isUKPhoneNum = value.startsWith('+447');
      const isInvalid = !isValidPhoneNumber(value);
      if ((!isUKPhoneNum && isInvalid) || (isUKPhoneNum && value.length > 13)) {
        return ERROR_MESSAGES.invalid;
      }
    }
  };

  return (
    <Column gapY={'units-unit8'}>
      <div
        className={cn(
          'h-[52px] shrink-0 px-padding5 bg-feedback-neutral-ghost-default rounded-units-unit8 font-medium text-content-body-default placeholder:text-content-label-alternative focus:outline-0 flex hover:bg-feedback-default-ghost-default focus:bg-feedback-default-ghost-default typography-body-default  items-center',
          {
            'bg-feedback-danger-ghost-default hover:bg-feedback-danger-ghost-default focus:bg-feedback-danger-ghost-default':
              error,
          },
        )}
      >
        <button
          type="button"
          disabled
          className="h-full text-content-subtext-alternative font-medium flex gap-x-1   pointer-events-none items-center"
        >
          <Flag code={defaultCountry as FlagCode} size="units-unit20" />+
          {getCountryCallingCode(defaultCountry as CountryCode)}
        </button>
        <PhoneInput
          control={control}
          name={name ?? ''}
          international
          country={defaultCountry}
          rules={{
            required: true,
            validate: validateHandler,
          }}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          placeholder={label}
          required
          className={cn(
            'outline-none caret-ui-border-active text-content-body-default font-medium bg-transparent flex-1 max-w-full size-full ml-units-unit8',
          )}
        />
      </div>
      {error && (
        <div
          className={cn(
            'flex w-full items-center gap-spacing-spacing2 text-content-subtext-default',
            {
              'text-feedback-danger-filled-default': !!error,
            },
          )}
        >
          <InformationCircle className={'size-units-unit12'} />
          <XXSmallBody>{error as string}</XXSmallBody>
        </div>
      )}
    </Column>
  );
};
