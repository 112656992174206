'use client';

import { useEffect } from 'react';

import { cn } from '@/lib/utils';

import { MoniepointIconLoader } from '../moniepoint-icon-loader';

export function PageLoader({
  background = 'transparent',
}: Readonly<{
  background?: 'opaque' | 'transparent';
}>) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div
      role="alert"
      aria-label="Loading"
      className={cn('fixed inset-0 !max-w-full z-loader', {
        'bg-neutral-700': background === 'opaque',
        'bg-black/80': background === 'transparent',
      })}
    >
      <MoniepointIconLoader
        classNames={{
          root: 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
        }}
      />
    </div>
  );
}
