'use client';

import { AlertModal, AlertModalProps } from '@kamona/components';
import { useState } from 'react';

import { CallBackWidget } from '@/features/shared/support/call-back/components';
import { EmailWidget } from '@/features/shared/support/email/components';
import {
  FeedbackDialog,
  FeedbackWidget,
} from '@/features/shared/support/feedback/components';
import { LiveChatWidget } from '@/features/shared/support/live-chat/components';

import { Card, CardProps } from '@/ui/components/card';
import { useDialog } from '@/ui/components/dialog';

import { checkUserLoggedIn } from '@/shared/utils';

import { cn } from '@/lib/utils';

import { CallBackDialog } from '../../call-back/components';
import { openWidget } from '../../utils/zendesk';

import { config } from '@/config';

export function SupportWidgets() {
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>();
  const isUserLoggedIn = checkUserLoggedIn();
  const {
    open: callbackDialogOpen,
    onOpenChange: onCallbackDialogOpenChange,
    onOpen: onCallbackDialogOpen,
  } = useDialog();
  const {
    open: feedbackDialogOpen,
    onOpenChange: onFeedbackDialogOpenChange,
    onOpen: onFeedbackDialogOpen,
  } = useDialog();

  const cardProps = {
    align: 'start',
    layout: 'vertical',
    mode: 'action',
    variant: 'outline',
    radius: 'large',
  } satisfies CardProps;

  const handleLiveChatClick = () => {
    openWidget();
  };

  return (
    <>
      <div
        className={cn(
          `grid gap-5 md:grid-cols-2 sm:grid-cols-1 `,
          isUserLoggedIn ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
        )}
      >
        <Card
          {...cardProps}
          className="px-5 py-units-unit32  gap-units-unit20 justify-start"
          onClick={handleLiveChatClick}
        >
          <LiveChatWidget />
        </Card>
        <Card
          {...cardProps}
          onClick={onCallbackDialogOpen}
          className="px-5 py-units-unit32  gap-units-unit20 justify-start"
        >
          <CallBackWidget isProfileAvailable={isUserLoggedIn} />
        </Card>
        <Card
          as="a"
          {...cardProps}
          className="px-5 py-units-unit32  gap-units-unit20 justify-start"
          href={`mailto:${config.SUPPORT_EMAIL}`}
        >
          <EmailWidget />
        </Card>
        {isUserLoggedIn && (
          <Card
            {...cardProps}
            onClick={onFeedbackDialogOpen}
            className="px-5 py-units-unit32 gap-units-unit20 justify-start"
          >
            <FeedbackWidget />
          </Card>
        )}
      </div>
      <CallBackDialog
        {...{
          open: callbackDialogOpen,
          onOpenChange: onCallbackDialogOpenChange,
          setAlertModalProps,
        }}
      />
      {isUserLoggedIn && (
        <FeedbackDialog
          {...{
            open: feedbackDialogOpen,
            onOpenChange: onFeedbackDialogOpenChange,
            setAlertModalProps,
          }}
        />
      )}
      {alertModalProps?.open && (
        <AlertModal
          className={{
            root: 'z-[1000]',
            backdrop: 'z-[999]',
          }}
          {...alertModalProps}
        />
      )}
    </>
  );
}
