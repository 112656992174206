'use client';

import { AlertModalProps, Typography } from '@kamona/components';
import { DialogProps } from '@radix-ui/react-dialog';
import { FormProvider } from 'react-hook-form';

import { Card, CardIcon } from '@/ui/components/card';
import {
  DialogAction,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/ui/components/dialog';
import { countryOptionsForPhoneInput } from '@/ui/components/phone-input/phone-input-data';
import { PhoneNumberInput } from '@/ui/components/phone-number-input';
import { SharedDialog } from '@/ui/components/shared-dialog';
import { CallMissedIcon } from '@/ui/svgs/call-missed-icon';
import { CallRingingIcon } from '@/ui/svgs/call-ringing-icon';

import { useSupportCallForm } from '../hooks';

import { config } from '@/config';

export function CallBackDialog({
  open,
  onOpenChange,
  setAlertModalProps,
}: Readonly<DialogProps> & {
  setAlertModalProps: (props: AlertModalProps) => void;
}) {
  const { isSubmitting, isSupportAvailable, onSubmit, methods } =
    useSupportCallForm({ setAlertModalProps, onOpenChange });

  return (
    <SharedDialog open={open} onOpenChange={onOpenChange}>
      <DialogHeader className="items-center">
        <DialogTitle>We are currently available</DialogTitle>
      </DialogHeader>
      <Card variant="outline">
        <CardIcon>
          {isSupportAvailable ? <CallRingingIcon /> : <CallMissedIcon />}
        </CardIcon>
        <Typography
          variant="body"
          color="content-subtext-default"
          className="flex-grow"
        >
          {isSupportAvailable
            ? 'Leave your phone number for a callback within 15-30min.'
            : 'Leave your phone number for a callback.'}{' '}
          <Typography
            as="span"
            untrimmed
            variant="body"
            weight="medium"
            color="content-heading-default"
          >
            Available {config.SUPPORT_AVAILABILITY}.
          </Typography>
        </Typography>
      </Card>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <PhoneNumberInput
            autoFocus
            variant="filled"
            inputMode="tel"
            label="Mobile number"
            placeholder="Mobile number"
            defaultCountry="GB"
            name="phoneNumber"
            required={{ value: true, message: 'Phone number is required' }}
            size="large"
            supportedCountries={countryOptionsForPhoneInput}
            isCountryDisabled
          />
          <DialogFooter align="stretch">
            <DialogClose
              onClick={() => onOpenChange?.(false)}
              appearance="plain"
              className={{ root: 'font-semibold' }}
            >
              Cancel
            </DialogClose>
            <DialogAction
              type="submit"
              disabled={isSubmitting || !methods.formState.isValid}
              loading={isSubmitting}
            >
              Submit
            </DialogAction>
          </DialogFooter>
        </form>
      </FormProvider>
    </SharedDialog>
  );
}
