'use client';

import { useSuppportModal } from '@/features/shared/support/modal';

import { HeaderButtonChip } from './header-chip';

type HeaderHelpChipProps = {
  onClick?: () => void;
  className?: string;
};

export function HeaderHelpChip({
  onClick,
  className,
}: Readonly<HeaderHelpChipProps>) {
  const { toggleSupportModal } = useSuppportModal();

  return (
    <HeaderButtonChip
      label="Need help?"
      onClick={onClick ?? toggleSupportModal}
      className={className}
    />
  );
}
