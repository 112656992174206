'use client';

import { useEffect } from 'react';
import { toast } from 'sonner';

import { KamonaSpinner } from '@/ui/svgs/kamona-spinner';

import { Button } from '../button';

interface NativePageErrorProps {
  useNativeRetry: true;
}

function areNativePageErrorProps(props: any): props is NativePageErrorProps {
  return props.hasOwnProperty('useNativeRetry');
}

interface CustomPageErrorProps {
  onRetry: () => void;
  isRetrying: boolean;
}

interface BasePageErrorProps {
  message: string;
}

export type PageErrorProps = BasePageErrorProps &
  (NativePageErrorProps | CustomPageErrorProps);

export function PageError({ message, ...props }: PageErrorProps) {
  // Disable scroll
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  // Display toast
  useEffect(() => {
    const isNative = areNativePageErrorProps(props);

    const onClickRetry = () =>
      isNative ? window.location.reload() : props.onRetry();

    let startIcon =
      !isNative && props.isRetrying ? (
        <KamonaSpinner className="h-4 w-4 text-ui-border-default" />
      ) : null;

    setTimeout(() => {
      toast.error(message, {
        duration: Infinity,
        action: (
          <Button
            size="small"
            variant="ghost"
            appearance="danger"
            onClick={onClickRetry}
            startIcon={startIcon}
            loading={isNative ? false : props.isRetrying}
          >
            Retry
          </Button>
        ),
      });
    });

    return () => {
      toast.dismiss();
    };
  }, [props, message]);

  // Render a transparent overlay to block interactions
  return (
    <div
      role="alert"
      aria-label={message}
      className="fixed inset-0 z-50 bg-transparent"
    />
  );
}
